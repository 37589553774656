import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import {useTranslation} from 'react-i18next';
import css from './integrations.module.scss';
import Footer from "../components/shared/Footer/Footer";
import OptiplusIntegrationsHero from "../components/sections/optiplusIntegrationsHero/OptiplusIntegrationsHero";
import ApiOptiplus from "../components/sections/apiOptiplus/ApiOptiplus";
import WebAppAccessOptiplus from "../components/sections/webAppAccessOptiplus/WebAppAccessOptiplus";
import { getRoutes } from '../components/shared/routes';

const IntegrationsPage = () => {
  const { t } = useTranslation();
  // const pageTitle = `${getRoutes().Integrations.pageTitle} | ${getRoutes().OptiwaiPlus.pageTitle}`;
  const pageTitle = `${getRoutes().Integrations.pageTitle} `;

  return (
    <Layout>
      <SEO title={pageTitle} description={'User friendly web interface, seamless API connectivity'}/>

      <div className={css.wrapper}>

        <div className={css.OptiplusIntegrationsHero}>
            <OptiplusIntegrationsHero />
        </div>

        <div className={css.ApiOptiplus}>
            <ApiOptiplus />
        </div>

        <div className={css.WebAppAccessOptiplus}>
            <WebAppAccessOptiplus />
        </div>

        <div className={css.Footer}>
          <Footer/>
        </div>

      </div>
    </Layout>
  );
};

export default IntegrationsPage;
