import React from 'react';
import css from "./apiOptiplus.module.scss";
import {useTranslation} from 'react-i18next';
import {
  IllustratedBox,
  ImagesContent,
  ImagesWrapper,
  Text,
  TextContent,
  TextHeader,
  TextWrapper
} from "../../shared/IllustratedBox/IllustratedBox";
import useBreakpoint from "../../hooks/useBreakpoint";
import {viewports} from "../../componentsUtils";
import useScale from "../../hooks/useScale";
import BoxHeader from "../../shared/BoxHeader/BoxHeader";
import ApiImg from "../../../images/sections/apiOptiplus/api.svg"

const ApiOptiplus = () => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const { phoneViewport, tabletViewport, desktopViewport } = viewports;
  const currentViewport = breakpoint.viewport;

  const scaleSettings = {
    [phoneViewport]: {
      baseScale: 1.6,
      maxScale: 0.6
    },
    [tabletViewport]: {
      baseScale: 1.2,
      maxScale: 0.65
    },
    [desktopViewport]: {
      baseScale: 0.85,
      maxScale: null
    }
  };

  const scale = useScale(scaleSettings[currentViewport].baseScale, scaleSettings[currentViewport].maxScale);

  const viewportSettings = {
    [phoneViewport]: {
      imagesPosition: 'top',
      textWrapperProps: {
        padding: '60px 20px 40px 20px'
      },
      imagesWrapperProps: {
        top: 20
      },
      imagesContentProps: {
        width: 600 * scale,
        height: 521 * scale,
      },
      boxHeaderPlace: 'top'
    },
    [tabletViewport]: {
      imagesPosition: 'top',
      textWrapperProps: {
        padding: '50px 16% 0 8%'
      },
      imagesWrapperProps: {
        top: 10
      },
      imagesContentProps: {
        width: 600 * scale,
        height: 521 * scale,
      },
      boxHeaderPlace: 'top'
    },
    [desktopViewport]: {
      imagesPosition: 'left',
      textWrapperProps: {
        padding: '0px 0 0 74px'
      },
      imagesWrapperProps: {
        top: 80
      },
      imagesContentProps: {
        width: 540 * scale,
        height: 441 * scale,
      },
      boxHeaderPlace: 'text'
    },
  };

  const {
    imagesPosition,
    textWrapperProps,
    imagesWrapperProps,
    imagesContentProps,
    boxHeaderPlace,
  } = viewportSettings[currentViewport];

  const imagesBox = (
    <ImagesWrapper {...imagesWrapperProps}>
      <ImagesContent {...imagesContentProps} className={css.imagesContent}>
        <img src={ApiImg} alt={'api'} />
      </ImagesContent>
    </ImagesWrapper>
  );

  const header = "API";

  const textBox = (
    <TextWrapper {...textWrapperProps}>
      {boxHeaderPlace === 'text' && <BoxHeader headerType={'h2'} lineColor={'green'} style={{ marginBottom: 30 }}>{header}</BoxHeader>}
      <TextContent>
        <TextHeader>
          <h3>Dedicated integration functionality with multiple systems</h3>
        </TextHeader>
        <Text>
          <p>Through open API enabling full integration with one or more systems for achieving productivity and performance scalability, as well as redundancy. </p><p>All registered users have full access to API documentation, installation packages and ready to use examples built-in inside your account. </p><p>API usage examples on your account are shown in a different programming languages like: Java Script, PHP, Python, Ruby, Java, C# and with additional generic example. </p><p>No matter you use Web interface or dedicated API the process is very fast and efficient.</p>
        </Text>
      </TextContent>
    </TextWrapper>
  );

  return (
    <IllustratedBox
      boxHeader={boxHeaderPlace === 'top' && header}
      boxHeaderLineColor={'green'}
      imagesBox={imagesBox}
      textBox={textBox}
      imagesPosition={imagesPosition}
    />
  );
};

export default ApiOptiplus;
