import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './optiplusIntegrationsHero.module.scss';
import {
  Background,
  IllustratedBox,
  ImagesContent,
  ImagesWrapper,
  TextContent,
  TextWrapper
} from "../../shared/IllustratedBox/IllustratedBox";
import SiteDescription from '../../shared/SiteDescription/SiteDescription';
import HeroImage from "../../../images/sections/optiplusIntegrationsHero/integrations_hero.svg";
import useBreakpoint from "../../hooks/useBreakpoint";
import {viewports} from "../../componentsUtils";
import useScale from "../../hooks/useScale";
import {optiPlusLoggingEnabled} from "../../shared/routes";

const OptiplusIntegrationsHero = () => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const { phoneViewport, tabletViewport, desktopViewport } = viewports;
  const currentViewport = breakpoint.viewport;

  const scaleSettings = {
    [phoneViewport]: {
      baseScale: 0.95,
      maxScale: 0.35
    },
    [tabletViewport]: {
      baseScale: 0.45,
      maxScale: 0.55
    },
    [desktopViewport]: {
      baseScale: 0.89,
      maxScale: null
    }
  };

  const scale = useScale(scaleSettings[currentViewport].baseScale, scaleSettings[currentViewport].maxScale);

  const viewportSettings = {
    [phoneViewport]: {
      textWrapperProps: {
        padding: '20px 8% 0 8%'
      },
      imagesPosition: 'top',
      imagesWrapperProps: {
        top: 140 * scale
      },
      imagesContentProps: {
        width: 780 * scale,
        height: 890 * scale,
      },
      imagesBackgroundProps: {
        top: 0 * scale,
        right: -152 * scale,
        width: 1062 * scale,
      }
    },
    [tabletViewport]: {
      textWrapperProps: {
        padding: '20px 16% 0 8%'
      },
      imagesPosition: 'top',
      imagesWrapperProps: {
        top: 0,
      },
      imagesContentProps: {
        width: 800 * scale,
        height: 860 * scale,
      },
      imagesBackgroundProps: {
        top: 0 * scale,
        left: -126 * scale,
        width: 1062 * scale,
      }
    },
    [desktopViewport]: {
      textWrapperProps: {
        padding: '70px 0 0 0'
      },
      imagesPosition: 'right',
      imagesWrapperProps: {
        top: 0,
        right: 0
      },
      imagesContentProps: {
        width: 660 * scale,
        height: 480 * scale,
      },
      imagesBackgroundProps: {
        top: 0 * scale,
        right: 40 * scale,
        width: 500 * scale,
      }
    }
  };

  const {
    textWrapperProps,
    imagesPosition,
    imagesWrapperProps,
    imagesContentProps,
    imagesBackgroundProps
  } = viewportSettings[currentViewport];

  const imagesBox = (
    <ImagesWrapper {...imagesWrapperProps}>
      <Background {...imagesBackgroundProps}>
        <img src={HeroImage} alt={'opti optiwai plus integrations api'} />
      </Background>
      <ImagesContent {...imagesContentProps} />
    </ImagesWrapper>
  );

  const textBox = (
    <div className={css.siteDescriptionWrapper}>
      <TextWrapper {...textWrapperProps}>
        <TextContent>
          <SiteDescription
            title={<h1>Integrations</h1>}
            content={(<div className={css.content}><p>Stick with the tools that works best for you.</p><p>Effortless and powerful integration options are available for all <strong><app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak></strong> users without any additional costs.</p><p>For your personal customised needs use powerful and well documented <strong><app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak></strong> API with native support and packages for many programming languages.</p></div>)}
            application={'OptiwaiPlus'}
            buttonShown={optiPlusLoggingEnabled}
            width={'100%'}
          />
        </TextContent>
      </TextWrapper>
    </div>
  );

  return (
    <div className={css.wrapper}>
      <IllustratedBox
        imagesBox={imagesBox}
        textBox={textBox}
        imagesPosition={imagesPosition}
      />
    </div>
  );
};

export default OptiplusIntegrationsHero;
